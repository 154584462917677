.backpage {
    .review-badge {
        border-radius: $spacer--4xl;
        color: $dark;
        border: solid toRem(1) $dark;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: toRem(6);
        width: 100%;
        padding: toRem(8);
        font-size: toRem(14);
        line-height: toRem(14);
        font-weight: 700;
        margin-bottom: $spacer;
    }

    .files-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacer;
        margin-top: $spacer--xl;

        .file-card,
        .file-card-cta {
            display: flex;
            flex-direction: column;
            padding: $spacer;
            border-radius: $border-radius;

            img {
                display: block;
                width: fit-content;
                height: toRem(40);
                margin-bottom: $spacer;
            }

            .file-card-title {
                font-weight: 700;
                line-height: toRem(24);
                flex: 1;
            }
        }

        .file-card {
            background-color: rgba($viridian, 0.1);

            img {
                display: block;
                width: fit-content;
                height: toRem(40);
                margin-bottom: $spacer;
            }

            .file-card-number {
                font-weight: 700;
                color: $coyote;
                text-align: right;
                align-self: flex-end;
            }
        }

        .file-card-cta {
            background-color: $viridian;
            transition: background-color 0.3s ease-in-out;

            .file-card-title {
                color: #fff;
            }

            svg {
                align-self: flex-end;
            }

            &:hover {
                background-color: $lapis-lazuli;
            }
        }
    }

    .comparison-chart {
        display: grid;
        margin-top: $spacer--2xl;
        margin-bottom: $spacer--4xl;

        .comparison-card {
            border: solid toRem(1) $lapis-lazuli;
        }

        .comparison-title {
            text-align: center;
            color: $lapis-lazuli;
            margin-top: 0;
            margin-bottom: 0;
            padding: $spacer--l $spacer;
            border-bottom: solid toRem(2) $lapis-lazuli;
        }

        .comparison-content {
            padding: $spacer--l;

            p {
                &:not(:last-child) {
                    margin-bottom: $spacer--xl;
                }
            }
        }
    }

    .comparison-card-dark {
        border-width: toRem(2);

        .comparison-title {
            background-color: $lapis-lazuli;
            color: #fff;
        }
    }

    .page-content {
        padding-top: $spacer--xl;
        padding-bottom: $spacer--4xl;

        .content {
            max-width: toRem(740);

            > h2 {
                &:not(:first-child) {
                    margin-top: $spacer--3xl;
                }
            }

            > p {
                margin-top: $spacer;
                margin-bottom: $spacer;
            }
        }
    }

    @include breakpoint-up(l) {
        .files-cards {
            grid-template-columns: repeat(4, 1fr);
        }

        .comparison-chart {
            margin-top: $spacer--4xl;
            grid-template-columns: repeat(3, 1fr);

            .comparison-card {
                &:not(:nth-child(2)) {
                    margin-top: $spacer;
                    margin-bottom: $spacer;
                }
            }

            .comparison-content {
                text-align: center;
                padding-top: $spacer--2xl;
                padding-bottom: $spacer--2xl;
            }
        }

        .page-content {
            padding-top: $spacer--4xl;
            padding-bottom: $spacer--5xl;

            .content {
                max-width: clamp(toRem(610), 57vw, toRem(740));

                > h2 {
                    &:not(:first-child) {
                        margin-top: $spacer--5xl;
                    }
                }
            }
        }
    }

    @include breakpoint-down(l) {
        .comparison-chart {
            gap: $spacer;
        }

        .page-content {
            .content {
                margin-inline: auto;
            }
        }
    }
}