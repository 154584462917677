$breakpoints: (
    s: 560px,
    l: 1068px,
    xl: 1440px
);

@mixin breakpoint-up($nameOrValue) {
    $breakpoint: if(type-of($nameOrValue) == 'number', $nameOrValue, map-get($breakpoints, $nameOrValue));
    @media only screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin breakpoint-down($nameOrValue) {
    $breakpoint: if(type-of($nameOrValue) == 'number', $nameOrValue, map-get($breakpoints, $nameOrValue));
    @media only screen and (max-width: ($breakpoint - 1)) {
        @content;
    }
}

@mixin breakpoint-between($lowerNameOrValue, $upperNameOrValue) {
    $lower_breakpoint: if(type-of($lowerNameOrValue) == 'number', $lowerNameOrValue, map-get($breakpoints, $lowerNameOrValue));
    $upper_breakpoint: if(type-of($upperNameOrValue) == 'number', $upperNameOrValue, map-get($breakpoints, $upperNameOrValue));
    @media only screen and (min-width: $lower_breakpoint) and (max-width: ($upper_breakpoint - 1)) {
        @content;
    }
}
