.about-us {
    .how-we-help,
    .why-choose-us {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    .who-we-help {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;

        .c-button-green {
            margin-top: $spacer--xl;
            margin-inline: auto;
        }
    }


    .section-header {
        max-width: toRem(800);
        margin-inline: auto;

        p:not(:last-child) {
            margin-bottom: $spacer;
        }
    }

    .image-cards {
        display: grid;
        gap: $spacer;
        grid-template-columns: repeat(2, 1fr);
        margin-top: $spacer--xl;

        .image-card {
            position: relative;
            border-radius: toRem(8);
            overflow: hidden;
            width: 100%;

            img {
                border-radius: toRem(8);
            }

            p {
                font-weight: 700;
                line-height: toRem(22);
                text-align: center;
            }
        }
    }

    @include breakpoint-up(l) {
        .how-we-help,
        .who-we-help,
        .why-choose-us {
            text-align: center;
            padding-top: $spacer--5xl;
            padding-bottom: $spacer--5xl;
        }

        .who-we-help {
            .c-button-green {
                margin-top: $spacer--3xl;
            }
        }

        .image-cards {
            margin-top: $spacer--3xl;
            grid-template-columns: repeat(4, 1fr);

            .image-card {
                img {
                    height: 100%;
                    object-fit: cover;
                }
    
                p {
                    position: absolute;
                    left: 0;
                    bottom: $spacer--l;
                    padding-left: $spacer--l;
                    z-index: 2;
                    color: #fff;
                    max-width: toRem(170);
                    text-align: left;
                }
    
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to bottom, rgba(36, 36, 41, 0.00) 25%, rgba(36, 36, 41, 0.95) 75%);
                    z-index: 1;
                }
            }
        }

        .action-blocks {
            grid-template-columns: repeat(2, 1fr);
            margin-top: $spacer--xl;

            .action-block--card {
                text-align: center;
            }
        }
    }

    @include breakpoint-down(s) {
        .who-we-help {
            .c-button-green {
                width: 100%;
            }
        }
    }
}