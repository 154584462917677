.accordion {
    display: flex;
    flex-direction: column;
    gap: $spacer--l;
    margin-top: $spacer--xl;

    .accordion-item {
        cursor: pointer;

        p:not(:last-child) {
            margin-bottom: $spacer;
        }

        .accordion-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: $spacer;

            .subheading {
                margin-bottom: 0;
            }
        }

        .accordion-content {
            height: 0;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
        }

        &.is-active .accordion-content {
            height: auto;
            opacity: 1;
            visibility: visible;
        }
    }
}

.accordion--cards {
    .accordion-item {
        border: solid toRem(1) $coyote;
        border-radius: $border-radius;
        padding: $spacer;
        cursor: pointer;
    
        p:not(:last-child) {
            margin-bottom: $spacer;
        }
    
        .accordion-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: $spacer;
    
            .subheading {
                margin-bottom: 0;
            }
        }
    
        .accordion-content {
            margin-top: 0;
            visibility: hidden;
            overflow: hidden;
            opacity: 0;
            transition: height 0.3s ease-out, visibility 0.3s ease-out, opacity 0.3s ease-out, margin-top 0.3s;
        }
    
        .icon {
            width: toRem(24);
            height: toRem(24);
            background: url('../../src/images/icons/plus.svg');
            background-repeat: no-repeat;
            background-size: contain;
            transition: background 0.3s;
        }
    
        &.is-active {
            .accordion-content {
                margin-top: $spacer--l;
                visibility: visible;
                opacity: 1;
                transition: height 0.3s ease-in, visibility 0.4s ease-in, opacity 0.3s ease-in, margin-top 0.3s;
            }
    
            .icon {
                background: url('../../src/images/icons/chevron-up.svg');
                background-repeat: no-repeat;
                background-size: contain;
                transition: background 0.2s ease-in;
            }
        }
    
        @include breakpoint-up(l) {
            padding: $spacer $spacer--l;
        }
    }
}

.accordion--border-simple {
    .accordion-item {
        &:not(:last-of-type) {
            border-bottom: solid toRem(1) $gray-20;
        }

        .accordion-header {
            margin-bottom: $spacer--l;
        }

        h4 {
            color: $dark;
            font-family: $font-family-serif;
            font-size: toRem(24);
            font-weight: 500;
        }

        .icon {
            width: toRem(14);
            height: toRem(14);
            background: url('../../src/images/icons/chevron-up.svg');
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(180deg);
            transition: background 0.3s, transform 0.5s;
        }
    
        &.is-active {
            .accordion-content {
                visibility: visible;
                opacity: 1;
                transition: height 0.3s ease-in, visibility 0.4s ease-in, opacity 0.3s ease-in, margin-top 0.3s;
            }
    
            .icon {
                opacity: 0.5;
                transform: rotate(0);
                transition: transform 0.5s;
            }
        }
    }
}