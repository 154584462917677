.why-choose-us {
    display: flex;
    flex-direction: column;
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;

    ul {
        margin-top: $spacer--l;
        margin-bottom: $spacer--l;
    }

    .why-choose-image {
        display: none;
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--6xl;
        padding-bottom: $spacer--6xl;
        flex-direction: row;
        gap: $spacer--4xl;

        .why-choose-image {
            display: block;
            max-width: toRem(336);
            border-radius: $border-radius;
        }
    }
}