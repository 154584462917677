.c-video {
    position: relative;

    .c-video__details {
        margin-bottom: $spacer;
        position: relative;
    }

    img {
        border-radius: $border-radius;
        display: block;
    }

    .c-icon-block {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
    }

    .c-video__icon {
        transition: transform 0.2s ease;
        --ggs: 2;
    }

    .c-video__caption {
        span {
            font-weight: 700;
            color: $viridian;
        }
    }

    &:hover {
        .c-video-image-container {
            position: relative;
            height: fit-content;
            border-radius: $border-radius;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
            }
        }
        .c-video__icon {
            --ggs: 2.5;
        }
    }

    @include breakpoint-up(l) {
        img {
            aspect-ratio: 8/7;
            object-fit: cover;
        }

        .c-video__icon {
            --ggs: 2.5;
        }

        &:hover {
            .c-video__icon {
                --ggs: 3;
            }
        }
    }
}

.video-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    overflow: hidden;

    &.active {
        display: flex;
    }

    .video-modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }

    .video-modal-content {
        position: relative;
        z-index: 1001;
        background-color: #000;
        border-radius: $border-radius;
        width: 100%;
        height: 100%;
        max-width: toRem(800);
        max-height: toRem(450);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            border: none;
        }
    }

    .video-modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
        z-index: 1002;

        &:hover {
            color: $gray-20;
        }
    }

    @include breakpoint-down(s) {
        .video-modal-content {
            margin-right: $spacer;
            margin-left: $spacer;
            max-height: clamp(toRem(180), 53vw, toRem(600));
        }
    }
}
