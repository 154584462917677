.trust-fund-available {
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;

    h3 {
        margin-top: $spacer--2xl;
        margin-bottom: $spacer--xl;
    }

    .keyfact-cards {
        display: grid;
        gap: $spacer;
    }

    .keyfact-card {
        border: solid toRem(1) $dark;
    }

    .keyfact-card,
    .keyfact-card-cta {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: $spacer;
        border-radius: $border-radius;
        gap: $spacer;
    }

    .keyfact-card-cta {
        background-color: $viridian;
        color: white;
        font-weight: 700;
        transition: background-color 0.3s ease-in;

        .arrow-right {
            align-self: flex-end;
            margin-top: auto;
        }

        &:hover {
            background-color: $lapis-lazuli;
        }
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--6xl;
        padding-bottom: $spacer--6xl;

        h3 {
            margin-top: $spacer--6xl;
            text-align: center;
        }

        .keyfact-cards {
            grid-template-columns: repeat(5, 1fr);
            gap: $spacer--l;
        }

        .keyfact-card,
        .keyfact-card-cta {
            padding: $spacer--l;
        }

        .keyfact-card,
        .keyfact-card-cta {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @include breakpoint-down(l) {
        .keyfact-card-cta {
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            border-radius: 0;

            .award-icon {
                display: none;
            }
    
            .arrow-right {
                margin-top: 0;
                align-self: center;
            }
        }

    }
}
.media-block-trust {
    display: flex;
    flex-direction: column;
    gap: $spacer--l;
    max-width: toRem(1264);
    margin-right: auto;
    margin-left: auto;

    .media-block-image {
        object-fit: cover;
    }

    @include breakpoint-up(l) {
        gap: $spacer--4xl;
        align-items: center;
        flex-direction: row;

        .media-block-image {
            height: toRem(400);
            width: clamp(47%, 40vw, toRem(660));
        }

        .media-block-content {
            width: clamp(50%, 40vw, toRem(540));
        }
    }

    @include breakpoint-down(l) {
        .media-block-image {
            width: 100%;
            height: clamp(toRem(200), 40vw, toRem(420));    
            object-position: 0 13%;
        }
    }

    @include breakpoint-down(s) {
        .media-block-image {
            @include make-full-width;
        }
    }

    @include breakpoint-up(s) {
        .media-block-image {
            border-radius: $border-radius;
        }
    }

}