.media-block {
    display: flex;
    flex-direction: column;

    p {
        margin-top: $spacer;
        margin-bottom: $spacer;

        &:first-of-type {
            margin-top: 0;
        }
    }

    @include breakpoint-up(l) {
        flex-direction: row;
        gap: $spacer--4xl;
    }
}

.media-block--media-small {
    .media-block__image {
        display: none;
    }

    @include breakpoint-up(l) {
        .media-block__image {
            display: block;
            max-width: toRem(336);
            border-radius: $border-radius;
        }
    }
}

.media-block--home {
    display: grid;
    gap: $spacer--xl;

    .media-block__image {
        display: block;
        border-radius: toRem(8);
        margin-right: auto;
        margin-left: auto;
    }

    .accordion {
        margin-top: 0;
    }

    @include breakpoint-up(l) {
        grid-template-columns: 1fr 1fr;
        gap: $spacer--4xl;
        align-items: center;
    }
}

.media-block--faq {
    @include breakpoint-up(l) {
        grid-template-columns: 1fr minmax(0, max-content);

        .media-block__image {
            max-width: toRem(480);
        }
    }
}