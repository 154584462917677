.action-block--card {
    border-radius: toRem(8);
    background-color: $cream;
    display: flex;
    flex-direction: column;
    padding: $spacer--xl;
    text-align: left;
    align-items: flex-start;
    gap: $spacer;

    img {
        width: fit-content;
    }

    @include breakpoint-up(l) {
        align-items: center;
    }
}

.action-block--card-border {
    border: toRem(1) solid $viridian;
    border-radius: $border-radius;
    background-color: #fff;
    padding: $spacer--l;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacer;
    box-shadow: toRem(4) toRem(4) toRem(4) 0 rgba(0, 0, 0, 0.10);

    h4 {
        font-size: toRem(19);
        font-weight: 700;
        line-height: toRem(26);
        color: $viridian;
    }

    svg {
        width: fit-content;
    }

    @include breakpoint-up(l) {
        align-items: center;
        flex-direction: column;
    }
}

.action-blocks {
    display: grid;
    gap: $spacer;

    @include breakpoint-up(l) {
        grid-template-columns: repeat(3, 1fr);
        gap: $spacer--xl;
    }
}