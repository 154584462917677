html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #434448;
  font-family: proxima-nova, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 26px;
}

@media only screen and (width >= 560px) {
  body {
    font-size: 18px;
    line-height: 28px;
  }
}

a {
  color: #242429;
  text-decoration: none;
}

ul {
  padding-left: 1.5rem;
}

h1 {
  color: #242429;
  margin-bottom: 1.5rem;
  font-family: Frank Ruhl Libre, serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
}

@media only screen and (width >= 560px) {
  h1 {
    font-size: 56px;
    line-height: 60px;
  }
}

h2 {
  color: #242429;
  margin-bottom: 1.5rem;
  font-family: Frank Ruhl Libre, serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
}

@media only screen and (width >= 560px) {
  h2 {
    font-size: 42px;
    line-height: 54px;
  }
}

h3 {
  color: #242429;
  margin-bottom: 1rem;
  font-family: proxima-nova, Helvetica Neue, sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
}

@media only screen and (width >= 560px) {
  h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

h5 {
  color: #242429;
  margin-bottom: 1rem;
  font-family: Frank Ruhl Libre, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}

@media only screen and (width >= 560px) {
  h5 {
    font-size: 24px;
    line-height: 33px;
  }
}

.subheading {
  color: #79603c;
  margin-bottom: 1.5rem;
  font-size: 18px;
  line-height: 25px;
}

@media only screen and (width >= 560px) {
  .subheading {
    font-size: 24px;
    line-height: 33px;
  }
}

.w-container {
  max-width: 94.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media only screen and (width >= 1068px) {
  .w-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.w-container-sm {
  max-width: 85.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media only screen and (width >= 1068px) {
  .w-container-sm {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.hide-mobile {
  display: none;
}

@media only screen and (width >= 560px) {
  .hide-mobile {
    display: block;
  }
}

.hide-desktop {
  display: block;
}

@media only screen and (width >= 560px) {
  .hide-desktop {
    display: none;
  }
}

.hidden {
  display: none;
}

.u-sr-only {
  clip: rect(0, 0, 0, 0);
  color: #000;
  white-space: nowrap;
  background-color: #fff;
  border: 0;
  width: .0625rem;
  height: .0625rem;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.cream-bg {
  background-color: #ebe5da;
}

.light-cream-bg {
  background-color: #f2eee6;
}

.viridian-bg {
  color: #fff;
  background-color: #1c365a;
}

.viridian-bg h2 {
  color: #fff;
}

.viridian-dark-bg {
  color: #fff;
  background-color: #1c365a;
}

.viridian-dark-bg h2 {
  color: #fff;
}

.viridian-blue-bg {
  color: #fff;
  background-color: #104571;
}

.viridian-blue-bg h2 {
  color: #fff;
}

.hide-l-down {
  display: none;
}

@media only screen and (width >= 1068px) {
  .hide-l-down {
    display: block;
  }
}

.accordion {
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  display: flex;
}

.accordion .accordion-item {
  cursor: pointer;
}

.accordion .accordion-item p:not(:last-child) {
  margin-bottom: 1rem;
}

.accordion .accordion-item .accordion-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.accordion .accordion-item .accordion-header .subheading {
  margin-bottom: 0;
}

.accordion .accordion-item .accordion-content {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: height .3s, opacity .3s, visibility .3s;
  overflow: hidden;
}

.accordion .accordion-item.is-active .accordion-content {
  opacity: 1;
  visibility: visible;
  height: auto;
}

.accordion--cards .accordion-item {
  cursor: pointer;
  border: .0625rem solid #79603c;
  border-radius: .5rem;
  padding: 1rem;
}

.accordion--cards .accordion-item p:not(:last-child) {
  margin-bottom: 1rem;
}

.accordion--cards .accordion-item .accordion-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.accordion--cards .accordion-item .accordion-header .subheading {
  margin-bottom: 0;
}

.accordion--cards .accordion-item .accordion-content {
  visibility: hidden;
  opacity: 0;
  margin-top: 0;
  transition: height .3s ease-out, visibility .3s ease-out, opacity .3s ease-out, margin-top .3s;
  overflow: hidden;
}

.accordion--cards .accordion-item .icon {
  background: url("plus.39f9ae99.svg") 0 0 / contain no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  transition: background .3s;
}

.accordion--cards .accordion-item.is-active .accordion-content {
  visibility: visible;
  opacity: 1;
  margin-top: 1.5rem;
  transition: height .3s ease-in, visibility .4s ease-in, opacity .3s ease-in, margin-top .3s;
}

.accordion--cards .accordion-item.is-active .icon {
  background: url("chevron-up.c564e23f.svg") 0 0 / contain no-repeat;
  transition: background .2s ease-in;
}

@media only screen and (width >= 1068px) {
  .accordion--cards .accordion-item {
    padding: 1rem 1.5rem;
  }
}

.accordion--border-simple .accordion-item:not(:last-of-type) {
  border-bottom: .0625rem solid #e4e4e6;
}

.accordion--border-simple .accordion-item .accordion-header {
  margin-bottom: 1.5rem;
}

.accordion--border-simple .accordion-item h4 {
  color: #242429;
  font-family: Frank Ruhl Libre, serif;
  font-size: 1.5rem;
  font-weight: 500;
}

.accordion--border-simple .accordion-item .icon {
  background: url("chevron-up.c564e23f.svg") 0 0 / contain no-repeat;
  width: .875rem;
  height: .875rem;
  transition: background .3s, transform .5s;
  transform: rotate(180deg);
}

.accordion--border-simple .accordion-item.is-active .accordion-content {
  visibility: visible;
  opacity: 1;
  transition: height .3s ease-in, visibility .4s ease-in, opacity .3s ease-in, margin-top .3s;
}

.accordion--border-simple .accordion-item.is-active .icon {
  opacity: .5;
  transition: transform .5s;
  transform: rotate(0);
}

.action-block--card {
  text-align: left;
  background-color: #ebe5da;
  border-radius: .5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 2rem;
  display: flex;
}

.action-block--card img {
  width: fit-content;
}

@media only screen and (width >= 1068px) {
  .action-block--card {
    align-items: center;
  }
}

.action-block--card-border {
  text-align: center;
  background-color: #fff;
  border: .0625rem solid #1c365a;
  border-radius: .5rem;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  display: flex;
  box-shadow: .25rem .25rem .25rem #0000001a;
}

.action-block--card-border h4 {
  color: #1c365a;
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.625rem;
}

.action-block--card-border svg {
  width: fit-content;
}

@media only screen and (width >= 1068px) {
  .action-block--card-border {
    flex-direction: column;
    align-items: center;
  }
}

.action-blocks {
  gap: 1rem;
  display: grid;
}

@media only screen and (width >= 1068px) {
  .action-blocks {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

.c-button-green {
  cursor: pointer;
  color: #fff;
  background-color: #1c365a;
  border: .0625rem solid #1c365a;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
  display: flex;
}

.c-button-green svg path {
  transition: fill .25s ease-in;
}

.c-button-green:hover {
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
}

.c-button-green:hover svg path {
  transition: fill .25s ease-in;
}

@media only screen and (width >= 1068px) {
  .c-button-green {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.c-button-green:hover {
  color: #fff;
  background-color: #355d7a;
}

.c-button-green-light {
  cursor: pointer;
  color: #242429;
  background-color: #53be7e;
  border: .0625rem solid #53be7e;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
  display: flex;
}

.c-button-green-light svg path {
  transition: fill .25s ease-in;
}

.c-button-green-light:hover {
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
}

.c-button-green-light:hover svg path {
  transition: fill .25s ease-in;
}

@media only screen and (width >= 1068px) {
  .c-button-green-light {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.c-button-green-light svg path {
  fill: #242429;
}

.c-button-green-light:hover {
  color: #fff;
  background-color: #104571;
  border-color: #104571;
}

.c-button-green-light:hover svg path {
  fill: #fff;
}

.c-button-green-light-dark-bg {
  cursor: pointer;
  color: #fff;
  background-color: #4a9d6b;
  border: .0625rem solid #4a9d6b;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
  display: flex;
}

.c-button-green-light-dark-bg svg path {
  transition: fill .25s ease-in;
}

.c-button-green-light-dark-bg:hover {
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
}

.c-button-green-light-dark-bg:hover svg path {
  transition: fill .25s ease-in;
}

@media only screen and (width >= 1068px) {
  .c-button-green-light-dark-bg {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.c-button-green-light-dark-bg svg path {
  fill: #fff;
}

.c-button-green-light-dark-bg:hover {
  color: #fff;
  background-color: #355d7a;
  border-color: #355d7a;
}

.c-button-green-light-dark-bg:hover svg path {
  fill: #fff;
}

.c-button-outline {
  cursor: pointer;
  color: #79603c;
  background-color: #fff;
  border: .0625rem solid #79603c;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
  display: flex;
}

.c-button-outline svg path {
  transition: fill .25s ease-in;
}

.c-button-outline:hover {
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
}

.c-button-outline:hover svg path {
  transition: fill .25s ease-in;
}

@media only screen and (width >= 1068px) {
  .c-button-outline {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.c-button-outline svg path {
  fill: #79603c;
}

.c-button-outline:hover {
  color: #fff;
  background-color: #79603c;
}

.c-button-outline:hover svg path {
  fill: #fff;
}

.c-call-button {
  border-radius: 10rem;
  transition: background-color .25s ease-in, color .25s ease-in;
  display: flex;
}

.c-call-button:hover {
  color: #fff;
  background-color: #1c365a;
  transition: background-color .25s ease-in, color .25s ease-in;
}

.c-call-button span {
  display: none;
}

@media only screen and (width >= 1068px) {
  .c-call-button {
    border: .125rem solid #242429;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: .25rem .5rem .25rem .25rem;
    font-size: 1rem;
  }

  .c-call-button span {
    display: block;
  }

  .c-call-button .phone-icon {
    background-color: #242429;
    border-radius: 5rem;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    display: flex;
  }

  .c-call-button .phone-icon svg {
    overflow: visible;
    transform: scale(.6);
  }

  .c-call-button .phone-icon svg path {
    fill: #fff;
  }
}

.c-button-coyote {
  cursor: pointer;
  color: #fff;
  background-color: #79603c;
  border: .0625rem solid #79603c;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
  display: flex;
}

.c-button-coyote svg path {
  transition: fill .25s ease-in;
}

.c-button-coyote:hover {
  transition: background-color .25s ease-in, color .25s ease-in, border-color .25s ease-in;
}

.c-button-coyote:hover svg path {
  transition: fill .25s ease-in;
}

@media only screen and (width >= 1068px) {
  .c-button-coyote {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.c-button-coyote svg path {
  fill: #fff;
}

.c-button-coyote:hover {
  color: #fff;
  background-color: #79603c;
}

.c-button-coyote:hover svg path {
  fill: #fff;
}

.accordion-button {
  color: #fff;
  cursor: pointer;
  background-color: #1c365a;
  border-radius: .5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-size: 1.125rem;
  line-height: 1.375rem;
  transition: background-color .25s ease-in, color .25s ease-in;
  display: flex;
}

.accordion-button:hover {
  color: #fff;
  background-color: #355d7a;
  transition: background-color .25s ease-in, color .25s ease-in;
}

@media only screen and (width >= 1068px) {
  .accordion-button {
    padding: 1rem 1.5rem;
  }
}

@media only screen and (width >= 560px) {
  .accordion-button {
    font-size: 1.5rem;
    line-height: 2.0625rem;
  }
}

.link {
  color: #1c365a;
  font-weight: 700;
  transition: color .25s ease-in;
}

.link:hover {
  color: #355d7a;
  transition: color .25s ease-in;
}

.cta-factbox {
  background-color: #fff;
  border: .0625rem solid #1c365a;
  border-radius: .5rem;
  padding: 1.5rem;
}

.cta-factbox ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
}

.cta-factbox .list-title {
  margin-bottom: 1.5rem;
  font-weight: 700;
}

@media only screen and (width >= 1068px) {
  .cta-factbox {
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem;
    display: grid;
  }

  .cta-factbox .c-button-green {
    height: fit-content;
  }
}

@media only screen and (width <= 1067px) {
  .cta-factbox .c-button-green {
    width: 100%;
    margin-top: 2rem;
  }
}

.cta-banner {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.cta-banner .cta-content {
  align-items: center;
  gap: 2rem;
  display: grid;
}

.cta-banner .c-button-green-light {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.cta-banner h3 {
  color: #fff;
  margin-bottom: 0;
}

@media only screen and (width >= 1068px) {
  .cta-banner .cta-content {
    grid-template-columns: 1fr max-content;
  }
}

.cta-home .cta-content p {
  margin-bottom: 1rem;
}

.cta-home img {
  display: block;
}

.cta-home .pill {
  color: #fff;
  border: .0625rem solid #fff;
  border-radius: 10rem;
  padding: .25rem 1rem;
  font-size: .875rem;
  display: inline-block;
}

.cta-home .c-button-green-light {
  color: #fff;
  background-color: #4a9d6b;
  border-color: #4a9d6b;
}

.cta-home .c-button-green-light svg path {
  fill: #fff;
}

.cta-home .c-button-green-light:hover {
  background-color: #355d7a;
  border-color: #355d7a;
}

@media only screen and (width >= 1068px) {
  .cta-home {
    color: #fff;
    align-items: center;
    min-height: 26.25rem;
    display: flex;
  }

  .cta-home .cta-container {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    display: flex;
  }

  .cta-home .cta-content p {
    margin-bottom: 2rem;
  }

  .cta-home .pill {
    margin-bottom: 2rem;
    font-size: 1rem;
  }

  .cta-home h2 {
    color: #fff;
  }

  .cta-home img {
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media only screen and (width <= 1067px) {
  .cta-home img {
    width: 100vw;
    max-width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
  }

  .cta-home h2 {
    margin-bottom: .5rem;
  }

  .cta-home .cta-content {
    padding-top: 2rem;
  }

  .cta-home .pill {
    margin-bottom: 1rem;
  }
}

.cta-footer {
  padding-top: 2rem;
}

.cta-footer h2 {
  margin-bottom: 2rem;
}

.cta-footer .cta-content {
  order: 1;
}

.cta-footer .cta-content p {
  margin-bottom: 1rem;
}

.cta-footer .pill {
  color: #fff;
  border: .0625rem solid #fff;
  border-radius: 10rem;
  margin-bottom: 2rem;
  padding: .25rem 1rem;
  font-size: .875rem;
  display: inline-block;
}

.cta-footer .cta-container {
  gap: 2rem;
  display: grid;
}

.cta-footer .cta-image {
  order: 2;
  display: block;
}

@media only screen and (width >= 1068px) {
  .cta-footer {
    padding-top: 2.5rem;
  }

  .cta-footer .cta-container {
    grid-template-columns: 1fr minmax(0, max-content);
    justify-content: center;
    align-items: center;
    gap: 4rem;
    height: 100%;
  }

  .cta-footer .cta-content p {
    margin-bottom: 2rem;
  }
}

@media only screen and (width <= 1067px) {
  .cta-footer .cta-content {
    padding-top: 2rem;
  }

  .cta-footer .cta-image {
    margin-inline: auto;
  }
}

@media only screen and (width <= 559px) {
  .cta-footer .c-button-green-light {
    width: 100%;
  }
}

.cta-inline {
  color: #fff;
  background-color: #1c365a;
  border-radius: .5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  overflow: hidden;
}

.cta-inline .cta-container {
  flex-direction: column;
  display: flex;
}

.cta-inline .cta-content {
  padding: 1.5rem 2rem;
}

.cta-inline .cta-overline {
  display: none;
}

.cta-inline .cta-title {
  margin-bottom: 1rem;
  font-family: Frank Ruhl Libre, serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.cta-inline .cta-image {
  align-self: center;
  display: block;
}

.cta-inline .cta-button {
  margin-top: 2rem;
}

@media only screen and (width >= 1068px) {
  .cta-inline .cta-container {
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }

  .cta-inline .cta-content {
    grid-area: 1 / 1 / auto / span 12;
  }
}

@media only screen and (width <= 1067px) {
  .cta-inline .cta-overline {
    border: .0625rem solid #fff;
    border-radius: 5rem;
    width: fit-content;
    margin-bottom: 2.5rem;
    padding: .5rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    display: block;
  }
}

@media only screen and (width <= 559px) {
  .cta-inline {
    width: 100vw;
    max-width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
  }

  .cta-inline .cta-image {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .cta-inline .cta-button {
    width: 100%;
  }
}

.cta-compact .cta-intro {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

.cta-compact .cta-container {
  background-color: #1c365a1a;
  border: .0625rem solid #1c365a;
  border-radius: .5rem;
  gap: 1rem;
  padding: .75rem 1rem;
  display: grid;
}

@media only screen and (width >= 1068px) {
  .cta-compact .cta-container {
    grid-template-columns: 1fr minmax(250px, 1fr);
    gap: 2.5rem;
  }

  .cta-compact .c-button-green {
    gap: 1rem;
  }
}

.cta-full-width {
  padding-bottom: 2rem;
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 1068px) {
  .cta-full-width {
    background: linear-gradient(to right, #242429cc 10%, #0000);
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.footer-page {
  text-align: center;
  color: #242429;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer-page .btn--ghost {
  color: #1c365a;
  cursor: pointer;
  background-color: #0000;
  border-color: #0000;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s ease-in-out;
}

.footer-page .btn--ghost:hover {
  color: #355d7a;
}

@media only screen and (width >= 1068px) {
  .footer-page .footer-content {
    text-align: left;
    grid-template-columns: 43.75rem max-content;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    display: grid;
  }
}

.footer-page--full {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer-page--full .footer-list {
  flex-direction: column;
  gap: 1.25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.footer-page--full .footer-list a {
  color: #1c365a;
  font-weight: 700;
}

.footer-page--full .footer-list a:hover {
  color: #1c365a;
}

.footer-page--full .footer-logo-image {
  max-width: 12.5rem;
  display: block;
}

.footer-page--full .footer-left h3 {
  margin-top: 2rem;
}

@media only screen and (width >= 1068px) {
  .footer-page--full {
    border-bottom: .0625rem solid #c3c3c3;
    padding-top: 0;
    padding-bottom: 0;
  }

  .footer-page--full .footer-top {
    text-align: left;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .footer-page--full .footer-left {
    border-right: .0625rem solid #c3c3c3;
  }

  .footer-page--full .footer-left h3 {
    margin-top: 3rem;
  }

  .footer-page--full .footer-left, .footer-page--full .footer-right {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .footer-page--full .footer-list {
    flex-direction: row;
    gap: 1.5rem;
  }

  .footer-page--full .footer-right {
    padding-left: 4rem;
  }
}

@media only screen and (width <= 1067px) {
  .footer-page--full {
    text-align: center;
  }

  .footer-page--full .footer-logo-image, .footer-page--full .c-button-green {
    margin-inline: auto;
    margin-bottom: 2rem;
  }
}

@media only screen and (width <= 559px) {
  .footer-page--full .c-button-green {
    width: 100%;
  }
}

.form {
  background-color: #fff;
  border: .125rem solid #1c365a;
  border-radius: .5rem;
  padding: 1rem;
}

.form .step {
  height: 100%;
  transition: opacity .5s ease-in-out;
  display: none;
}

.form .step.is-active {
  display: block;
}

.form .form-group {
  margin-bottom: 15px;
}

.form .form-group label {
  margin-bottom: 5px;
  display: block;
}

.form .form-group input, .form .form-group select, .form .form-group textarea {
  box-sizing: border-box;
  color: #434448;
  border: .0625rem solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: .75rem;
  font-family: proxima-nova, Helvetica Neue, sans-serif;
  font-size: 1.125rem;
  line-height: 1.0625rem;
}

.form .form-group select {
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z\" fill=\"currentColor\"/></svg>") calc(100% - 10px) / 1.125rem 1.125rem no-repeat;
  height: 3rem;
}

.form .form-group .error-message {
  color: red;
  margin-top: 5px;
  font-size: .8em;
}

.form .c-button-green {
  justify-content: center;
  width: 100%;
}

.form .button-group {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  display: grid;
}

.form .button-group .c-button-green, .form .button-group .c-button-outline {
  width: 100%;
}

.form .form-disclaimer {
  text-align: center;
  margin-top: 1rem;
  font-size: .875rem;
  line-height: 1.375rem;
}

.form .form-disclaimer a {
  color: #1c365a;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (width <= 1067px) {
  .form {
    text-align: left;
  }
}

@media only screen and (width >= 1068px) {
  .form {
    padding: 2rem;
  }

  .form .form-group-two-column {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    display: grid;
  }
}

.c-form__group--onnohoney {
  display: none !important;
}

.c-header {
  background-color: #fff;
}

.c-header .c-header-content {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.c-header .logo {
  width: 100%;
  max-width: 12.5rem;
  display: block;
}

@media only screen and (width >= 1068px) {
  .c-header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media only screen and (width <= 1067px) {
  .c-header .c-header-content {
    height: 100%;
    min-height: 4.375rem;
  }

  .c-header .w-container {
    padding: 0;
  }

  .c-header .c-call-button {
    border-left: .0625rem solid #e4e4e6;
    border-radius: 0;
    height: 100%;
    padding: 1.4375rem clamp(1rem, 5vw, 2rem);
  }

  .c-header .phone-icon {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.nav .nav-list {
  flex-direction: row;
  gap: 2rem;
  margin: 0;
  font-weight: 700;
  list-style: none;
  display: flex;
  position: relative;
}

.nav .nav-list a:hover {
  color: #1c365a;
}

.nav .nav-list li {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (width <= 1067px) {
  .nav {
    z-index: 100;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transition: left .5s ease-in;
    position: absolute;
    top: 4.375rem;
    left: -75rem;
  }

  .nav .nav-list {
    flex-direction: column;
    gap: 0;
    padding: 1.5rem;
  }

  .nav .nav-list li {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .nav .nav-list li:not(:first-of-type) {
    border-top: .0625rem solid #e4e4e6;
    padding-top: 1rem;
  }

  .nav.is-active {
    transition: left .5s ease-out;
    left: 0;
  }
}

.ham-menu {
  height: 100%;
}

.ham-menu .hamburger-button {
  background-color: unset;
  border: unset;
  border-right: .0625rem solid #e4e4e6;
  height: 100%;
  padding: 1.625rem 1rem;
}

.ham-menu .hamburger {
  cursor: pointer;
  width: 1.75rem;
  height: 1.125rem;
  display: block;
  position: relative;
}

.ham-menu .hamburger.is-active .line-bar--1 {
  width: 100%;
  top: 50%;
  transform: rotate(45deg);
}

.ham-menu .hamburger.is-active .line-bar--2 {
  opacity: 0;
}

.ham-menu .hamburger.is-active .line-bar--3 {
  width: 100%;
  top: 50%;
  transform: rotate(-45deg);
}

.ham-menu .line-bar {
  background-color: #1c365a;
  border-radius: .125rem;
  width: 100%;
  height: .1875rem;
  margin-top: -.09375rem;
  display: block;
  position: absolute;
  left: 0;
}

.ham-menu .line-bar--1 {
  width: 50%;
  transition: top .2s, transform .2s;
  top: 0%;
  transform: rotate(0);
}

.c-hamburger.is-active .ham-menu .line-bar--1 {
  top: 50%;
  transform: rotate(45deg);
}

.ham-menu .line-bar--2 {
  opacity: 1;
  transition: opacity .1s;
  top: 50%;
}

.c-hamburger.is-active .ham-menu .line-bar--2 {
  opacity: 0;
  transition: opacity .2s;
}

.ham-menu .line-bar--3 {
  width: 80%;
  transition: top .2s, transform .2s;
  top: 100%;
  transform: rotate(0);
}

.c-hamburger.is-active .ham-menu .line-bar--3 {
  top: 50%;
  transform: rotate(-45deg);
}

@media only screen and (width >= 1068px) {
  .ham-menu {
    display: none;
  }
}

.has-dropdown {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.has-dropdown .link__parent {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.has-dropdown .dropdown-menu {
  z-index: 200;
  background-color: #fff;
  padding: 2rem 2.5rem;
  display: none;
  box-shadow: 0 .25rem .5rem #0000001a;
}

.has-dropdown .dropdown-menu a {
  color: #1c365a;
  display: block;
}

.has-dropdown .dropdown-menu a:hover {
  text-decoration: underline;
}

.has-dropdown .gg {
  transition: transform .5s;
}

@media only screen and (width <= 1067px) {
  .has-dropdown .dropdown-menu {
    box-shadow: none;
    width: 100%;
    margin-left: 1rem;
    padding: 1rem 0 0;
    display: none;
    position: relative;
  }

  .has-dropdown .dropdown-menu a {
    margin-bottom: .75rem;
  }

  .has-dropdown .link__parent {
    justify-content: space-between;
    width: 100%;
  }

  .has-dropdown.is-open .dropdown-menu {
    display: block;
  }

  .has-dropdown.is-open .gg {
    transform: rotate(0);
  }
}

@media only screen and (width >= 1068px) {
  .has-dropdown .dropdown-menu {
    border: 1px solid #e4e4e6;
    min-width: 40rem;
    position: absolute;
    top: 3.75rem;
    left: 2rem;
  }

  .has-dropdown:hover .dropdown-menu {
    flex-direction: column;
    gap: 1.25rem;
    display: flex;
  }

  .has-dropdown:hover .gg {
    transform: rotate(0);
  }
}

.gg {
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  display: block;
  position: relative;
}

.gg--chevron-up, .gg--chevron-down, .gg--chevron-left, .gg--chevron-right {
  border: 2px solid #0000;
  border-radius: 100px;
}

.gg--chevron-up:after, .gg--chevron-down:after, .gg--chevron-left:after, .gg--chevron-right:after {
  content: "";
  box-sizing: border-box;
  border-top: 2px solid;
  border-right: 2px solid;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 2px;
  left: 4px;
  transform: rotate(-45deg);
}

.gg--chevron-down {
  transform: rotate(180deg);
}

.gg--chevron-left {
  transform: rotate(-90deg);
}

.gg--chevron-right {
  transform: rotate(90deg);
}

.c-hero {
  padding-bottom: 3rem;
}

.c-hero .c-hero-content {
  align-items: center;
  gap: 2rem;
  display: grid;
}

.c-hero .subheading-p {
  color: #434448;
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 2rem;
}

@media only screen and (width >= 1068px) {
  .c-hero {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .c-hero h1 {
    margin-bottom: 2rem;
  }

  .c-hero .c-hero-content {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  .c-hero .subheading-p {
    font-size: 1.375rem;
  }

  .c-hero .hero-image {
    border-radius: .5rem;
  }
}

@media only screen and (width <= 1067px) {
  .c-hero .main-content {
    order: 2;
  }

  .c-hero .aside-content {
    order: 1;
  }

  .c-hero .hero-image {
    object-fit: cover;
    object-position: 0 13%;
    width: 100vw;
    max-width: 100vw;
    height: clamp(12.5rem, 40vw, 26.25rem);
    margin-left: -50vw;
    margin-right: -50vw;
    display: block;
    position: relative;
    left: 50%;
    right: 50%;
  }
}

.c-hero-lp .c-hero-lp-content {
  gap: 1rem;
  padding-bottom: 2rem;
  display: grid;
}

.c-hero-lp .hero-card {
  border: .0625rem solid #242429;
  border-radius: .5rem;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.c-hero-lp .hero-card img {
  width: fit-content;
}

.c-hero-lp .hero-cards {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

.c-hero-lp .hero-image {
  object-fit: cover;
}

.c-hero-lp .subheading-p {
  color: #434448;
  font-size: 1.1875rem;
  line-height: 1.75rem;
}

@media only screen and (width >= 1068px) {
  .c-hero-lp {
    padding-top: 2.5rem;
    padding-bottom: 5rem;
  }

  .c-hero-lp .c-hero-lp-content {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 4rem;
  }

  .c-hero-lp .aside-content {
    order: 2;
    height: fit-content;
  }

  .c-hero-lp .main-content {
    flex-direction: column;
    order: 1;
    justify-content: space-between;
    height: 100%;
    display: flex;
  }

  .c-hero-lp .form {
    margin-top: 2rem;
  }

  .c-hero-lp .hero-cards-mobile {
    display: none;
  }

  .c-hero-lp .hero-image {
    border-radius: .5rem;
    width: 100%;
    height: 30rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (width <= 1067px) {
  .c-hero-lp .c-hero-lp-content .main-content {
    order: 1;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }

  .c-hero-lp .c-hero-lp-content .aside-content {
    margin-inline: auto;
    order: 2;
    width: fit-content;
    margin-bottom: 1.5rem;
  }

  .c-hero-lp .hero-image {
    object-position: 0 13%;
    width: 100%;
    height: clamp(12.5rem, 40vw, 26.25rem);
  }

  .c-hero-lp .hero-cards {
    padding-top: 1.5rem;
  }

  .c-hero-lp .hero-cards-desktop {
    display: none;
  }
}

@media only screen and (width >= 560px) {
  .c-hero-lp .hero-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .c-hero-lp .hero-card {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (width <= 1067px) {
  .c-hero-lp .hero-image {
    width: 100vw;
    max-width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
  }
}

@media only screen and (width >= 560px) and (width <= 1067px) {
  .c-hero-lp .main-content {
    text-align: center;
  }
}

.c-hero-backpage {
  color: #fff;
  background-color: #1c365a;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.c-hero-backpage .main-content {
  width: 100%;
}

.c-hero-backpage .main-content h1 {
  color: #fff;
}

.c-hero-backpage .main-content p {
  font-size: 1.375rem;
  line-height: 2rem;
}

.c-hero-backpage .aside-content {
  width: 100%;
  display: none;
}

.c-hero-backpage .hero-image {
  border-radius: .5rem;
  margin-bottom: 2rem;
  display: block;
  overflow: hidden;
}

.c-hero-backpage .form-title {
  font-family: Frank Ruhl Libre, serif;
}

.c-hero-backpage .form {
  background-color: #e4eff44d;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.c-hero-backpage .form .button-group {
  grid-template-columns: 1fr;
}

.c-hero-backpage .form select {
  height: 3.25rem;
}

.c-hero-backpage .form button {
  width: 100%;
}

@media only screen and (width >= 1068px) {
  .c-hero-backpage {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .c-hero-backpage .c-hero-content {
    gap: 4rem;
    margin-left: -4rem;
    margin-right: -4rem;
    display: flex;
    position: relative;
  }

  .c-hero-backpage .main-content {
    max-width: 43.125rem;
    padding-left: 4rem;
    padding-right: 1rem;
  }

  .c-hero-backpage .aside-content {
    color: #242429;
    z-index: 50;
    background-color: #fff;
    border-radius: .5rem .5rem 0 0;
    max-width: 23.75rem;
    height: 100%;
    min-height: 31.25rem;
    padding: 1rem;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .c-hero-backpage .content {
    margin-bottom: 2rem;
  }

  .c-hero-backpage .sticky-form {
    border: .125rem solid #1c365a;
    border-radius: .5rem;
    max-width: 21.75rem;
    padding: 1rem;
  }

  .c-hero-backpage .sticky-form.is-sticky {
    position: fixed;
  }

  .c-hero-backpage .form {
    background-color: unset;
    border: unset;
    padding: 0;
  }
}

@media only screen and (width <= 1067px) {
  .c-hero-backpage {
    padding-bottom: 0;
  }

  .c-hero-backpage .main-content {
    max-width: 46.25rem;
    margin-inline: auto;
  }

  .c-hero-backpage .aside-content {
    background-color: #fff;
    width: 100vw;
    max-width: 100vw;
    margin-top: 1.5rem;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: block;
    position: relative;
    left: 50%;
    right: 50%;
  }

  .c-hero-backpage .sticky-form {
    color: #242429;
    max-width: 46.25rem;
    margin-inline: auto;
  }
}

@media (width >= 74.375rem) {
  .c-hero-backpage .main-content {
    max-width: 49.5rem;
  }
}

.c-hero-backpage--form .image-content img {
  width: 100%;
}

@media only screen and (width >= 1068px) {
  .c-hero-backpage--form {
    background: linear-gradient(#1c365a 75%, #fff 75%);
    padding-bottom: 0;
  }
}

@media only screen and (width >= 1335px) {
  .c-hero-backpage--form .image-content {
    width: 123%;
    position: relative;
  }
}

.gg--play-button {
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 20px;
  width: 22px;
  height: 22px;
  display: block;
  position: relative;
}

.gg--play-button:before {
  content: "";
  box-sizing: border-box;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 6px solid;
  width: 0;
  height: 10px;
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
}

.media-block {
  flex-direction: column;
  display: flex;
}

.media-block p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.media-block p:first-of-type {
  margin-top: 0;
}

@media only screen and (width >= 1068px) {
  .media-block {
    flex-direction: row;
    gap: 4rem;
  }
}

.media-block--media-small .media-block__image {
  display: none;
}

@media only screen and (width >= 1068px) {
  .media-block--media-small .media-block__image {
    border-radius: .5rem;
    max-width: 21rem;
    display: block;
  }
}

.media-block--home {
  gap: 2rem;
  display: grid;
}

.media-block--home .media-block__image {
  border-radius: .5rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.media-block--home .accordion {
  margin-top: 0;
}

@media only screen and (width >= 1068px) {
  .media-block--home {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 4rem;
  }

  .media-block--faq {
    grid-template-columns: 1fr minmax(0, max-content);
  }

  .media-block--faq .media-block__image {
    max-width: 30rem;
  }
}

dialog {
  border: 0;
  display: block;
}

.no-scroll {
  position: relative;
  overflow: hidden;
}

.simple-modal-overlay {
  opacity: .6;
  z-index: 666;
  cursor: pointer;
  background: #000;
  position: fixed;
  inset: 0;
}

.simple-modal-overlay[data-background-click="disabled"] {
  cursor: auto;
}

.simple-modal {
  overflow: visible;
}

.simple-modal > * {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.simple-modal-close {
  z-index: 668;
  cursor: pointer;
  background-color: #0000;
  border: .1875rem solid #79603c;
  border-radius: 1em;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
  line-height: 1;
  position: fixed;
  top: .25rem;
  right: .25rem;
  border-radius: 50% !important;
}

@media only screen and (width >= 1068px) {
  .simple-modal-close {
    top: 1rem;
    right: 1rem;
  }
}

.simple-modal__content {
  z-index: 667;
  background: #fff;
  border-radius: .5rem;
  width: 90%;
  max-height: 88vh;
  padding: 2rem 1rem;
  position: fixed;
  top: 7%;
  left: 5%;
  right: auto;
  overflow: scroll;
}

.simple-modal__content p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (width >= 1068px) {
  .simple-modal__content {
    width: 70%;
    padding: 4rem;
    left: 15%;
  }
}

.simple-modal-close {
  background-color: #fff;
}

.related-pages {
  max-width: 20.75rem;
}

.related-pages .related-pages-title {
  color: #242429;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.related-pages .related-list {
  padding-left: 0;
}

.related-pages .list-item {
  border-bottom: .0625rem solid #e4e4e6;
  flex-direction: row;
  align-items: center;
  gap: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: flex;
}

.related-pages .list-item a {
  margin-left: .5rem;
}

.related-pages .list-item a:hover {
  color: #1c365a;
}

.related-pages .list-item svg {
  overflow: visible;
  transform: scale(.5);
}

.related-pages .list-item svg path {
  fill: #1c365a;
}

.related-pages .list-item:not(:first-of-type) {
  padding-top: .75rem;
}

.toc-mobile {
  display: none;
}

.toc-mobile.is-visible {
  transition: top .5s ease-out;
  top: 0;
}

.toc-mobile .related-list {
  background-color: #fff;
  height: 0;
  min-height: 0;
  margin: 0;
  list-style: none;
  transition: min-height .5s ease-out;
  overflow: hidden;
}

@media only screen and (width <= 1067px) {
  .toc-mobile {
    width: 100%;
    transition: top .5s ease-in;
    display: block;
    position: fixed;
    top: -31.25rem;
    box-shadow: 0 .375rem .875rem #2424291a;
  }

  .toc-mobile .toc-button {
    border-radius: 0;
    width: 100%;
    font-size: .875rem;
  }

  .toc-mobile .toc-button svg {
    overflow: visible;
    transform: scale(.7);
  }

  .toc-mobile .toc-button.is-active svg {
    transform: rotate(180deg);
  }

  .toc-mobile .related-list.is-active {
    width: 100%;
    height: 100%;
    min-height: 12.5rem;
    padding: 1rem 1.5rem 2rem;
    transition: min-height .5s ease-in;
  }

  .toc-mobile .list-item {
    border-bottom: .0625rem solid #e4e4e6;
    padding: .5rem 0;
  }
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  border: .0625rem solid #c3c3c3;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: .0625rem solid #c3c3c3;
  width: 33.33%;
  padding: .75rem;
}

.table th:not(:last-child), .table td:not(:last-child) {
  border-right: .0625rem solid #c3c3c3;
}

.table th {
  font-weight: 700;
}

.table tr:nth-child(odd) {
  background-color: #ebe5da;
}

@media only screen and (width >= 1068px) {
  .table th:not(:first-child), .table td:not(:first-child) {
    text-align: center;
  }
}

.trust-bar {
  border-top: .0625rem solid #e4e4e6;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.trust-bar h3 {
  margin-bottom: 0;
}

.trust-bar .trust-bar-title {
  text-transform: uppercase;
  color: #008868;
  text-align: center;
  font-size: .875rem;
  font-weight: 600;
}

.trust-bar .trust-bar-content {
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  display: flex;
}

.trust-bar .trust-item {
  grid-template-columns: 2rem 1fr;
  gap: 1.5rem;
  display: grid;
}

.trust-bar .trust-item .svg-container {
  width: 2rem;
  height: auto;
}

.trust-bar .trust-item svg {
  width: 100%;
  height: auto;
  display: block;
}

@media only screen and (width >= 560px) and (width <= 1067px) {
  .trust-bar .trust-bar-content {
    flex-flow: wrap;
  }

  .trust-bar .trust-item {
    flex: 50%;
  }
}

@media only screen and (width >= 1068px) {
  .trust-bar .trust-bar-content {
    flex-direction: row;
  }

  .trust-bar .trust-item {
    max-width: 18.75rem;
  }

  .trust-bar .trust-item:not(:last-child) {
    border-right: .0625rem solid #c3c3c3;
    margin-right: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (width <= 1067px) {
  .trust-bar .trust-bar-content {
    row-gap: 1.5rem;
  }
}

.c-video {
  position: relative;
}

.c-video .c-video__details {
  margin-bottom: 1rem;
  position: relative;
}

.c-video img {
  border-radius: .5rem;
  display: block;
}

.c-video .c-icon-block {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-video .c-video__icon {
  --ggs: 2;
  transition: transform .2s;
}

.c-video .c-video__caption span {
  color: #1c365a;
  font-weight: 700;
}

.c-video:hover .c-video-image-container {
  border-radius: .5rem;
  height: fit-content;
  position: relative;
  overflow: hidden;
}

.c-video:hover .c-video-image-container:before {
  content: "";
  background: #0000004d;
  width: 100%;
  height: 100%;
  position: absolute;
}

.c-video:hover .c-video__icon {
  --ggs: 2.5;
}

@media only screen and (width >= 1068px) {
  .c-video img {
    aspect-ratio: 8 / 7;
    object-fit: cover;
  }

  .c-video .c-video__icon {
    --ggs: 2.5;
  }

  .c-video:hover .c-video__icon {
    --ggs: 3;
  }
}

.video-modal-wrapper {
  z-index: 1000;
  background: #000c;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.video-modal-wrapper.active {
  display: flex;
}

.video-modal-wrapper .video-modal-bg {
  cursor: pointer;
  background: #0000004d;
  position: absolute;
  inset: 0;
}

.video-modal-wrapper .video-modal-content {
  z-index: 1001;
  background-color: #000;
  border-radius: .5rem;
  width: 100%;
  max-width: 50rem;
  height: 100%;
  max-height: 28.125rem;
  position: relative;
  box-shadow: 0 4px 20px #0000004d;
}

.video-modal-wrapper .video-modal-content iframe {
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.video-modal-wrapper .video-modal-close {
  color: #fff;
  cursor: pointer;
  z-index: 1002;
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.video-modal-wrapper .video-modal-close:hover {
  color: #e4e4e6;
}

@media only screen and (width <= 559px) {
  .video-modal-wrapper .video-modal-content {
    max-height: clamp(11.25rem, 53vw, 37.5rem);
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.trust-fund-available {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.trust-fund-available h3 {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.trust-fund-available .keyfact-cards {
  gap: 1rem;
  display: grid;
}

.trust-fund-available .keyfact-card {
  border: .0625rem solid #242429;
}

.trust-fund-available .keyfact-card, .trust-fund-available .keyfact-card-cta {
  border-radius: .5rem;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.trust-fund-available .keyfact-card-cta {
  color: #fff;
  background-color: #1c365a;
  font-weight: 700;
  transition: background-color .3s ease-in;
}

.trust-fund-available .keyfact-card-cta .arrow-right {
  align-self: flex-end;
  margin-top: auto;
}

.trust-fund-available .keyfact-card-cta:hover {
  background-color: #355d7a;
}

@media only screen and (width >= 1068px) {
  .trust-fund-available {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .trust-fund-available h3 {
    text-align: center;
    margin-top: 6rem;
  }

  .trust-fund-available .keyfact-cards {
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
  }

  .trust-fund-available .keyfact-card, .trust-fund-available .keyfact-card-cta {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
  }
}

@media only screen and (width <= 1067px) {
  .trust-fund-available .keyfact-card-cta {
    text-align: center;
    border-radius: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .trust-fund-available .keyfact-card-cta .award-icon {
    display: none;
  }

  .trust-fund-available .keyfact-card-cta .arrow-right {
    align-self: center;
    margin-top: 0;
  }
}

.media-block-trust {
  flex-direction: column;
  gap: 1.5rem;
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.media-block-trust .media-block-image {
  object-fit: cover;
}

@media only screen and (width >= 1068px) {
  .media-block-trust {
    flex-direction: row;
    align-items: center;
    gap: 4rem;
  }

  .media-block-trust .media-block-image {
    width: clamp(47%, 40vw, 41.25rem);
    height: 25rem;
  }

  .media-block-trust .media-block-content {
    width: clamp(50%, 40vw, 33.75rem);
  }
}

@media only screen and (width <= 1067px) {
  .media-block-trust .media-block-image {
    object-position: 0 13%;
    width: 100%;
    height: clamp(12.5rem, 40vw, 26.25rem);
  }
}

@media only screen and (width <= 559px) {
  .media-block-trust .media-block-image {
    width: 100vw;
    max-width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
  }
}

@media only screen and (width >= 560px) {
  .media-block-trust .media-block-image {
    border-radius: .5rem;
  }
}

.qualify-for-compenstation {
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

.qualify-for-compenstation .qualify-main p {
  margin-bottom: 1.5rem;
}

.qualify-for-compenstation .qualify-cards {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.qualify-for-compenstation .qualify-card {
  background-color: #f2eee6;
  border: .0625rem solid #242429;
  border-radius: .5rem;
  flex-direction: column;
  padding: 1.5rem;
  display: flex;
}

.qualify-for-compenstation .qualify-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
  margin-bottom: 1rem;
  display: flex;
}

.qualify-for-compenstation .qualify-header h3 {
  margin-bottom: 0;
}

.qualify-for-compenstation .qualify-header span {
  color: #1c365a;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.5rem;
}

@media only screen and (width >= 1068px) {
  .qualify-for-compenstation {
    flex-direction: row;
    gap: 4rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .qualify-for-compenstation .qualify-header {
    margin-bottom: 1.5rem;
  }

  .qualify-for-compenstation .c-button-green--desktop {
    margin-top: 2rem;
  }

  .qualify-for-compenstation .c-button-green--mobile {
    display: none;
  }
}

@media only screen and (width <= 1067px) {
  .qualify-for-compenstation .c-button-green--mobile {
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;
  }

  .qualify-for-compenstation .c-button-green--desktop {
    display: none;
  }
}

.why-choose-us {
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

.why-choose-us ul {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.why-choose-us .why-choose-image {
  display: none;
}

@media only screen and (width >= 1068px) {
  .why-choose-us {
    flex-direction: row;
    gap: 4rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .why-choose-us .why-choose-image {
    border-radius: .5rem;
    max-width: 21rem;
    display: block;
  }
}

.thank-you {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.backpage .review-badge {
  color: #242429;
  border: .0625rem solid #242429;
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  gap: .375rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: .875rem;
  display: flex;
}

.backpage .files-cards {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 2rem;
  display: grid;
}

.backpage .files-cards .file-card, .backpage .files-cards .file-card-cta {
  border-radius: .5rem;
  flex-direction: column;
  padding: 1rem;
  display: flex;
}

.backpage .files-cards .file-card img, .backpage .files-cards .file-card-cta img {
  width: fit-content;
  height: 2.5rem;
  margin-bottom: 1rem;
  display: block;
}

.backpage .files-cards .file-card .file-card-title, .backpage .files-cards .file-card-cta .file-card-title {
  flex: 1;
  font-weight: 700;
  line-height: 1.5rem;
}

.backpage .files-cards .file-card {
  background-color: #1c365a1a;
}

.backpage .files-cards .file-card img {
  width: fit-content;
  height: 2.5rem;
  margin-bottom: 1rem;
  display: block;
}

.backpage .files-cards .file-card .file-card-number {
  color: #79603c;
  text-align: right;
  align-self: flex-end;
  font-weight: 700;
}

.backpage .files-cards .file-card-cta {
  background-color: #1c365a;
  transition: background-color .3s ease-in-out;
}

.backpage .files-cards .file-card-cta .file-card-title {
  color: #fff;
}

.backpage .files-cards .file-card-cta svg {
  align-self: flex-end;
}

.backpage .files-cards .file-card-cta:hover {
  background-color: #355d7a;
}

.backpage .comparison-chart {
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  display: grid;
}

.backpage .comparison-chart .comparison-card {
  border: .0625rem solid #355d7a;
}

.backpage .comparison-chart .comparison-title {
  text-align: center;
  color: #355d7a;
  border-bottom: .125rem solid #355d7a;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1.5rem 1rem;
}

.backpage .comparison-chart .comparison-content {
  padding: 1.5rem;
}

.backpage .comparison-chart .comparison-content p:not(:last-child) {
  margin-bottom: 2rem;
}

.backpage .comparison-card-dark {
  border-width: .125rem;
}

.backpage .comparison-card-dark .comparison-title {
  color: #fff;
  background-color: #355d7a;
}

.backpage .page-content {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.backpage .page-content .content {
  max-width: 46.25rem;
}

.backpage .page-content .content > h2:not(:first-child) {
  margin-top: 3rem;
}

.backpage .page-content .content > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (width >= 1068px) {
  .backpage .files-cards {
    grid-template-columns: repeat(4, 1fr);
  }

  .backpage .comparison-chart {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;
  }

  .backpage .comparison-chart .comparison-card:not(:nth-child(2)) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .backpage .comparison-chart .comparison-content {
    text-align: center;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .backpage .page-content {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  .backpage .page-content .content {
    max-width: clamp(38.125rem, 57vw, 46.25rem);
  }

  .backpage .page-content .content > h2:not(:first-child) {
    margin-top: 5rem;
  }
}

@media only screen and (width <= 1067px) {
  .backpage .comparison-chart {
    gap: 1rem;
  }

  .backpage .page-content .content {
    margin-inline: auto;
  }
}

.about-us .how-we-help, .about-us .why-choose-us, .about-us .who-we-help {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.about-us .who-we-help .c-button-green {
  margin-top: 2rem;
  margin-inline: auto;
}

.about-us .section-header {
  max-width: 50rem;
  margin-inline: auto;
}

.about-us .section-header p:not(:last-child) {
  margin-bottom: 1rem;
}

.about-us .image-cards {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 2rem;
  display: grid;
}

.about-us .image-cards .image-card {
  border-radius: .5rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.about-us .image-cards .image-card img {
  border-radius: .5rem;
}

.about-us .image-cards .image-card p {
  text-align: center;
  font-weight: 700;
  line-height: 1.375rem;
}

@media only screen and (width >= 1068px) {
  .about-us .how-we-help, .about-us .who-we-help, .about-us .why-choose-us {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .about-us .who-we-help .c-button-green {
    margin-top: 3rem;
  }

  .about-us .image-cards {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 3rem;
  }

  .about-us .image-cards .image-card img {
    object-fit: cover;
    height: 100%;
  }

  .about-us .image-cards .image-card p {
    z-index: 2;
    color: #fff;
    text-align: left;
    max-width: 10.625rem;
    padding-left: 1.5rem;
    position: absolute;
    bottom: 1.5rem;
    left: 0;
  }

  .about-us .image-cards .image-card:before {
    content: "";
    z-index: 1;
    background: linear-gradient(#24242900 25%, #242429f2 75%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .about-us .action-blocks {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 2rem;
  }

  .about-us .action-blocks .action-block--card {
    text-align: center;
  }
}

@media only screen and (width <= 559px) {
  .about-us .who-we-help .c-button-green {
    width: 100%;
  }
}

.financial-assistance {
  background-color: #ebe5da;
}

.financial-assistance .get-started {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.financial-assistance .get-started .link {
  color: #ebe5da;
  text-underline-offset: calc(2 / 16 * 1rem);
  text-decoration: underline;
}

.financial-assistance .get-started .action-block h3 {
  color: #fff;
  margin-top: 1.5rem;
}

@media only screen and (width >= 1068px) {
  .financial-assistance .get-started h2 {
    margin-bottom: 3rem;
  }
}

@media only screen and (width <= 559px) {
  .financial-assistance .get-started .action-block h3 {
    margin-bottom: .5rem;
  }
}

.find-attorney .c-hero-lp {
  background-color: #e4e4e64d;
}

.find-attorney .benefits-of-filing, .find-attorney .get-started {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.find-attorney .meso-lawyer-can-help {
  background-color: #f7f7f7;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.find-attorney .meso-lawyer-can-help .media-block__quote .overline {
  text-align: center;
  margin-bottom: 1.5rem;
}

.find-attorney .benefits-of-filing {
  background-color: #c3c3c34d;
}

.find-attorney .benefits-of-filing .action-block--card {
  background-color: #fff;
}

.find-attorney .benefits-of-filing .action-block--card img {
  max-width: 4rem;
}

.find-attorney .get-started {
  text-align: center;
  border-bottom: .0625rem solid #e4e4e6;
}

.find-attorney .get-started .description {
  margin-inline: auto;
  max-width: 46.875rem;
  margin-bottom: 4rem;
}

.find-attorney .get-started .action-block h3 {
  color: #fff;
  margin-top: 1.5rem;
}

.find-attorney .get-started .action-block img {
  max-width: 5.25rem;
}

.find-attorney .get-started .action-block p {
  font-size: 1rem;
}

.find-attorney .overline {
  text-transform: uppercase;
  letter-spacing: .0625rem;
  color: #64a6a2;
  margin-bottom: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.125rem;
}

.find-attorney .featured-in {
  background-color: #fff;
  border-radius: .5rem;
  margin-top: 2.5rem;
  padding: 2rem 1.5rem;
}

.find-attorney .featured-in .featured-imgs {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem 1.5rem;
  display: flex;
}

.find-attorney .featured-in .featured-imgs img {
  height: fit-content;
}

@media only screen and (width >= 1068px) {
  .find-attorney .subheading-p {
    margin-bottom: 0;
  }

  .find-attorney .hero-image {
    max-height: 40.625rem;
    margin-bottom: 0;
  }

  .find-attorney .media-block--media-small .media-block__image {
    object-fit: cover;
    height: 100%;
    max-height: 28.125rem;
  }

  .find-attorney .benefits-of-filing {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .find-attorney .benefits-of-filing h1 {
    margin-bottom: 2rem;
  }

  .find-attorney .benefits-of-filing .action-blocks {
    grid-template-columns: repeat(2, 1fr);
  }

  .find-attorney .meso-lawyer-can-help {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .find-attorney .meso-lawyer-can-help .media-block {
    align-items: center;
  }

  .find-attorney .meso-lawyer-can-help .media-block__quote {
    min-width: 31.25rem;
  }

  .find-attorney .cta-factbox {
    margin-top: 2rem;
  }

  .find-attorney .action-block--card {
    text-align: center;
  }
}

@media only screen and (width <= 1067px) {
  .find-attorney .hero-image {
    object-position: 0 50%;
  }

  .find-attorney .media-block--media-small .media-block__image {
    object-fit: cover;
    width: 100%;
    max-height: 21rem;
    display: block;
  }

  .find-attorney .media-block__media {
    margin-bottom: 1.5rem;
  }

  .find-attorney .meso-lawyer-can-help .media-block {
    flex-direction: column-reverse;
    gap: 2.5rem;
  }

  .find-attorney .meso-lawyer-can-help .media-block__quote {
    margin-inline: auto;
  }

  .find-attorney .featured-in {
    text-align: center;
  }

  .find-attorney .featured-in .featured-imgs {
    justify-content: center;
  }
}

@media only screen and (width >= 560px) and (width <= 1067px) {
  .find-attorney .media-block--media-small .media-block__image {
    border-radius: .5rem;
  }
}

@media only screen and (width <= 559px) {
  .find-attorney .media-block--media-small .media-block__image {
    width: 100vw;
    max-width: 100vw;
    max-height: 14.25rem;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
  }

  .find-attorney .get-started .action-block h3 {
    margin-bottom: .5rem;
  }
}

.homepage .who-is-eligible, .homepage .types-meso-claims, .homepage .advocating, .homepage .empowering {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.homepage .action-blocks {
  gap: 1.5rem;
}

.homepage .empowering .w-container-sm {
  max-width: 58rem;
}

.homepage .who-is-eligible h2 {
  margin-bottom: 2rem;
}

.homepage .action-blocks {
  margin-top: 2rem;
}

@media only screen and (width >= 1068px) {
  .homepage .c-hero .hero-image {
    object-fit: cover;
    height: 26.875rem;
  }

  .homepage .who-is-eligible, .homepage .types-meso-claims, .homepage .advocating, .homepage .empowering {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .homepage .who-is-eligible .section-header, .homepage .types-meso-claims .section-header {
    text-align: center;
  }

  .homepage .who-is-eligible h2 {
    margin-bottom: 4rem;
  }

  .homepage .empowering h2 {
    text-align: center;
  }

  .homepage .action-blocks {
    margin-top: 4rem;
  }
}

@media only screen and (width <= 1067px) {
  .homepage .c-hero .hero-image {
    object-position: unset;
  }
}

.data-request .is-desktop, .privacy-policy .is-desktop {
  display: none;
}

.data-request .is-mobile, .privacy-policy .is-mobile {
  display: block;
}

.data-request .c-hero-lp, .privacy-policy .c-hero-lp {
  background-color: #e4e4e64d;
  margin-bottom: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.data-request .c-hero-lp h1, .privacy-policy .c-hero-lp h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 2.5rem;
  line-height: 3.125rem;
}

.data-request h2, .privacy-policy h2 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 2.5rem;
}

.data-request p, .data-request li, .privacy-policy p, .privacy-policy li {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.data-request p a.anchor, .data-request li a.anchor, .privacy-policy p a.anchor, .privacy-policy li a.anchor {
  color: #1871c3;
  text-decoration: underline;
}

.data-request .form, .privacy-policy .form {
  border: .0625rem solid #e4e4e6;
  border-radius: .5rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
  padding: 0;
  box-shadow: 0 .25rem 1.25rem #00000040;
}

.data-request .form .form-title, .privacy-policy .form .form-title {
  color: #fff;
  background-color: #1c365a;
  border-radius: .5rem .5rem 0 0;
  padding: 1.875rem 2rem;
  font-family: Frank Ruhl Libre, serif;
  font-size: 1.6875rem;
  font-weight: 400;
  line-height: normal;
}

.data-request .form fieldset, .privacy-policy .form fieldset {
  padding: 2.5rem 2rem;
}

.data-request .form .form-inline, .privacy-policy .form .form-inline {
  flex-flow: wrap;
  gap: 1.25rem;
  width: 100%;
  display: flex;
}

.data-request .form .form-inline > *, .privacy-policy .form .form-inline > * {
  flex: 300px;
}

.data-request .form .form-group select, .privacy-policy .form .form-group select {
  width: 100%;
  height: 3.375rem;
  margin-bottom: 0;
}

.data-request .form .radio-group, .privacy-policy .form .radio-group {
  align-items: center;
  gap: 2rem;
  display: flex;
}

.data-request .form .radio-group .radio-item, .privacy-policy .form .radio-group .radio-item {
  align-items: center;
  gap: .625rem;
  display: flex;
}

.data-request .form .radio-group .radio-item input[type="radio"], .privacy-policy .form .radio-group .radio-item input[type="radio"] {
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  border: .125rem solid #e4e4e6;
  border-radius: .3125rem;
  width: .9375rem;
  height: .9375rem;
  margin-left: 0;
  position: relative;
}

.data-request .form .radio-group .radio-item input[type="radio"]:checked, .privacy-policy .form .radio-group .radio-item input[type="radio"]:checked {
  background-color: #1871c3;
  border-color: #1871c3;
}

.data-request .form .radio-group .radio-item input[type="radio"]:checked:after, .privacy-policy .form .radio-group .radio-item input[type="radio"]:checked:after {
  content: "✓";
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.data-request .form .radio-group .radio-item input[type="radio"]:focus, .privacy-policy .form .radio-group .radio-item input[type="radio"]:focus {
  outline: .125rem solid #1871c3;
}

.data-request .form .radio-group .radio-item input[type="radio"]:hover, .privacy-policy .form .radio-group .radio-item input[type="radio"]:hover {
  border-color: #1871c3;
}

.data-request .form .radio-group label, .privacy-policy .form .radio-group label {
  margin-bottom: 0;
  line-height: normal;
}

.data-request .form .row-left, .privacy-policy .form .row-left {
  justify-content: flex-end;
  margin-top: .625rem;
  display: flex;
}

.data-request .form .row-between, .privacy-policy .form .row-between {
  justify-content: space-between;
  margin-top: .625rem;
  display: flex;
}

.data-request .form .row-between .c-button-green, .privacy-policy .form .row-between .c-button-green {
  width: unset;
}

.data-request .form small, .privacy-policy .form small {
  color: #838383;
  margin: .625rem 0;
  font-size: .875rem;
  font-style: italic;
  line-height: 1.2;
  display: block;
}

@media only screen and (width >= 1068px) {
  .data-request .is-desktop, .privacy-policy .is-desktop {
    display: block;
  }

  .data-request .is-mobile, .privacy-policy .is-mobile {
    display: none;
  }

  .data-request .c-hero-lp, .privacy-policy .c-hero-lp {
    margin-bottom: 3.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .data-request .c-hero-lp h1, .privacy-policy .c-hero-lp h1 {
    text-align: left;
    font-size: 2.625rem;
  }

  .data-request h2, .privacy-policy h2 {
    font-size: 2.125rem;
  }

  .data-request .form, .privacy-policy .form {
    margin-top: 6rem;
  }

  .data-request .form .form-inline, .privacy-policy .form .form-inline {
    flex-wrap: nowrap;
  }

  .data-request .row-left button, .data-request .row-between button, .privacy-policy .row-left button, .privacy-policy .row-between button {
    width: unset;
  }
}

html {
  scroll-padding-top: 1.5rem;
}

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

img {
  max-width: 100%;
  height: auto;
}

sup {
  vertical-align: super;
  margin-left: .1rem;
  margin-right: .1rem;
  font-size: .8rem;
  position: relative;
  top: -.1rem;
}
/*# sourceMappingURL=index.728760a2.css.map */
