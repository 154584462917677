@mixin button-styles {
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: center;
    gap: $spacer;
    font-size: toRem(18);
    line-height: toRem(22);
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.25s ease-in, color 0.25s ease-in, border-color 0.25s ease-in;

    svg path {
        transition: fill 0.25s ease-in;
    }

    &:hover {
        transition: background-color 0.25s ease-in, color 0.25s ease-in, border-color 0.25s ease-in;

        svg path {
            transition: fill 0.25s ease-in;
        }
    }

    @include breakpoint-up(l) {
        padding: $spacer--m $spacer--xl;
        font-size: toRem(20);
        line-height: toRem(24);
    }
}

.c-button-green {
    @include button-styles;
    background-color: $viridian;
    color: white;
    padding: $spacer;
    border: solid toRem(1) $viridian;
    border-radius: toRem(8);

    &:hover {
        background-color: $lapis-lazuli;
        color: white;
    }
}

.c-button-green-light {
    @include button-styles;
    background-color: $viridian-light;
    color: $dark;
    padding: $spacer;
    border: solid toRem(1) $viridian-light;
    border-radius: toRem(8);

    svg path {
        fill: $dark;
    }

    &:hover {
        background-color: $viridian-blue;
        border-color: $viridian-blue;
        color: white;

        svg path {
            fill: white;
        }
    }
}

.c-button-green-light-dark-bg {
    @include button-styles;
    background-color: #4a9d6b;
    color: white;
    padding: $spacer;
    border: solid toRem(1) #4a9d6b;
    border-radius: toRem(8);

    svg path {
        fill: white;
    }

    &:hover {
        background-color: $lapis-lazuli;
        border-color: $lapis-lazuli;
        color: white;

        svg path {
            fill: white;
        }
    }
}

.c-button-outline {
    @include button-styles;
    background-color: white;
    color: $coyote;
    padding: $spacer;
    border: solid toRem(1) $coyote;
    border-radius: toRem(8);

    svg path {
        fill: $coyote;
    }

    &:hover {
        background-color: $coyote;
        color: white;

        svg path {
            fill: white;
        }
    }
}

.c-call-button {
    border-radius: 10rem;
    display: flex;
    transition: background-color 0.25s ease-in, color 0.25s ease-in;

    &:hover {
        background-color: $viridian;
        color: white;
        transition: background-color 0.25s ease-in, color 0.25s ease-in;
    }

    span {
        display: none;
    }

    @include breakpoint-up(l) {
        align-items: center;
        justify-content: center;
        font-size: toRem(16);
        gap: toRem(8);
        padding: $spacer--2xs $spacer--xs $spacer--2xs $spacer--2xs;
        border: solid toRem(2) $dark;

        span {
            display: block;
        }

        .phone-icon {
            width: toRem(32);
            height: toRem(32);
            background-color: $dark;
            border-radius: $spacer--5xl;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                transform: scale(0.6);
                overflow: visible;

                path {
                    fill: #fff;
                }
            }
        }
    }
}

.c-button-coyote {
    @include button-styles;
    background-color: $coyote;
    color: white;
    padding: $spacer;
    border: solid toRem(1) $coyote;
    border-radius: toRem(8);

    svg path {
        fill: white;
    }

    &:hover {
        background-color: $coyote;
        color: white;

        svg path {
            fill: white;
        }
    }
}

.accordion-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $viridian;
    color: white;
    align-items: center;
    gap: $spacer;
    padding: $spacer;
    border-radius: $border-radius;
    transition: background-color 0.25s ease-in, color 0.25s ease-in;
    font-size: toRem(18);
    line-height: toRem(22);
    cursor: pointer;

    &:hover {
        background-color: $lapis-lazuli;
        color: white;
        transition: background-color 0.25s ease-in, color 0.25s ease-in;
    }

    @include breakpoint-up(l) {
        padding: $spacer $spacer--l;
    }

    @include breakpoint-up(s) {
        font-size: toRem(24);
        line-height: toRem(33);
    }
}

.link {
    color: $viridian;
    font-weight: 700;
    transition: color 0.25s ease-in;

    &:hover {
        color: $lapis-lazuli;
        transition: color 0.25s ease-in;
    }
}