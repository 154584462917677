.trust-bar {
    padding-top: toRem(48);
    padding-bottom: toRem(48);
    border-top: toRem(1) solid #E4E4E6;

    h3 {
        margin-bottom: 0;
    }

    .trust-bar-title {
        font-size: toRem(14);
        text-transform: uppercase;
        color: #008868;
        font-weight: 600;
        text-align: center;
    }

    .trust-bar-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: $spacer--xl;
    }

    .trust-item {
        display: grid;
        grid-template-columns: toRem(32) 1fr;
        gap: $spacer--l;

        .svg-container {
            width: toRem(32);
            height: auto;
        }

        svg {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    @include breakpoint-between(s,l) {
        .trust-bar-content {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .trust-item {
            flex: 50%;
        }
    }

    @include breakpoint-up(l) {
        .trust-bar-content {
            flex-direction: row;
        }

        .trust-item {
            max-width: toRem(300);

            &:not(:last-child) {
                border-right: solid toRem(1) $gray-30;
                padding-right: $spacer--xl;
                margin-right: $spacer--xl;
            }
        }
    }

    @include breakpoint-down(l) {
        .trust-bar-content {
            row-gap: $spacer--l;
        }
    }
}