.data-request,
.privacy-policy {

  .is-desktop {
    display: none;
  }

  .is-mobile {
    display: block;
  }

  .c-hero-lp {
    background-color: rgba($gray-20, 0.3);
    padding-top: $spacer--3xl;
    padding-bottom: $spacer--3xl;
    margin-bottom: $spacer--xl;

    h1 {
      font-size: toRem(40);
      line-height: toRem(50);
      margin-bottom: 0;
      text-align: center;
    }
  }

  h2 {
    font-size: toRem(32);
    line-height: toRem(40);
    margin-bottom: $spacer--l;
  }

  p, li {
    font-size: toRem(18);
    line-height: toRem(30);
    margin-bottom: $spacer--l;

    a.anchor {
      color: $marine;
      text-decoration: underline;
    }
  }

  .form {
    border-radius: toRem(8);
    border: toRem(1) solid $gray-20;
    box-shadow: 0 toRem(4) toRem(20) 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    margin-bottom: $spacer--6xl;
    margin-top: $spacer--xl;

    .form-title {
      font-family: $font-family-serif;
      font-size: toRem(27);
      line-height: normal;
      font-weight: 400;
      color: $white;
      background-color: $viridian;
      padding: toRem(30) $spacer--xl;
      border-radius: toRem(8) toRem(8) 0 0;
    }

    fieldset {
      padding: $spacer--2xl $spacer--xl;
    }

    .form-inline {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $spacer--m;
      width: 100%;

      > * {
        flex: 1 1 300px;
      }
    }

    .form-group {
      select {
        width: 100%;
        margin-bottom: 0;
        height: toRem(54);
      }
    }

    .radio-group {
      display: flex;
      align-items: center;
      gap: toRem(32);

      .radio-item {
        display: flex;
        align-items: center;
        gap: toRem(10);

        input[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          width: toRem(15);
          height: toRem(15);
          border-radius: toRem(5);
          border: toRem(2) solid $gray-20;
          background-color: $white;
          position: relative;
          cursor: pointer;
          margin-left: 0;

          &:checked {
            background-color: $marine;
            border-color: $marine;

            &::after {
              content: '✓';
              position: absolute;
              top: 50%;
              left: 45%;
              transform: translate(-50%, -50%);
              font-size: toRem(16);
              font-weight: 700;
              color: $white;
            }
          }

          &:focus {
            outline: toRem(2) solid $marine;
          } 

          &:hover {
            border-color: $marine;
          }
        }
      }

      label {
        margin-bottom: 0;
        line-height: normal;
      }
    }

    .row-left {
      display: flex;
      justify-content: flex-end;
      margin-top: toRem(10);
    }

    .row-between {
      display: flex;
      justify-content: space-between;
      margin-top: toRem(10);

      .c-button-green {
        width: unset;
      }
    }

    small {
      font-size: toRem(14);
      line-height: 1.2;
      color: $gray-50;
      font-style: italic;
      display: block;
      margin: toRem(10) 0;
    }
  }

  @include breakpoint-up(l) {

    .is-desktop {
      display: block;
    }

    .is-mobile {
      display: none;
    }

    .c-hero-lp {
      padding-top: $spacer--5xl;
      padding-bottom: $spacer--5xl;
      margin-bottom: toRem(56);

      h1 {
        font-size: toRem(42);
        text-align: left;
      }
    }

    h2 {
      font-size: toRem(34);
    }

    .form {
      margin-top: $spacer--6xl;

      .form-inline {
        flex-wrap: nowrap;
      }
    }

    .row-left,
    .row-between {
      button {
        width: unset;
      }
    }
  }
}
