.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: $spacer--3xl;
    margin-bottom: $spacer--3xl;
    text-align: left;
    border: toRem(1) solid $gray-30;

    th,
    td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: toRem(1) solid $gray-30;
        width: 33.33%;

        &:not(:last-child) {
            border-right: toRem(1) solid $gray-30;
        }
    }

    th {
        font-weight: 700;
    }

    tr:nth-child(odd) {
        background-color: $cream;
    }

    @include breakpoint-up(l) {
        th,
        td {
            &:not(:first-child) {
                text-align: center;
            }
        }
    }
}
