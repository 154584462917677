.financial-assistance {
    background-color: $cream;

    .get-started {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    .get-started {
        text-align: center;

        .link {
            color: $cream;
            text-decoration: underline;
            text-underline-offset: toRem(2);
        }

        .action-block {
            h3 {
                color: #fff;
                margin-top: $spacer--l;
            }
        }
    }

    @include breakpoint-up(l) {
        .get-started {
            h2 {
                margin-bottom: $spacer--3xl;
            }
        }
    }

    @include breakpoint-down(s) {
        .get-started {
            .action-block {
                h3 {
                    margin-bottom: $spacer--xs;
                }
            }
        }
    }
}