.c-header {
    background-color: #fff;

    .c-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $spacer--s;
    }

    .logo {
        display: block;
        width: 100%;
        max-width: toRem(200);
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--l;
        padding-bottom: $spacer--l;
    }

    @include breakpoint-down(l) {
        .c-header-content {
            height: 100%;
            min-height: toRem(70);
        }

        .w-container {
            padding: 0;
        }

        .c-call-button {
            height: 100%;
            padding: toRem(23) clamp(toRem(16), 5vw, toRem(32));
            border-left: solid toRem(1) $gray-20;
            border-radius: 0;
        }

        .phone-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.nav {
    .nav-list {
        display: flex;
        flex-direction: row;
        position: relative;
        gap: $spacer--xl;
        list-style: none;
        font-weight: 700;
        margin: 0;

        a {
            &:hover {
                color: $viridian-dark;
            }
        }

        li {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
    }

    @include breakpoint-down(l) {
        position: absolute;
        top: toRem(70);
        left: toRem(-1200);
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: #fff;
        transition: left 0.5s ease-in;

        .nav-list {
            flex-direction: column;
            gap: 0;
            padding: $spacer--l;

            li {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                &:not(:first-of-type) {
                    border-top: solid toRem(1) $gray-20;
                    padding-top: $spacer;
                }
            }
        }

        &.is-active {
            left: 0;
            transition: left 0.5s ease-out;
        }
    }
}

.ham-menu {
    height: 100%;

    .hamburger-button {
        background-color: unset;
        border: unset;
        border-right: solid toRem(1) $gray-20;
        padding: toRem(26) $spacer;
        height: 100%;
    }

    .hamburger {
        cursor: pointer;
        display: block;
        height: toRem(18);
        position: relative;
        width: toRem(28);

        &.is-active {
            .line-bar--1 {
                top: 50%;
                transform: rotate(45deg);
                width: 100%;
            }

            .line-bar--2 {
                opacity: 0;
            }

            .line-bar--3 {
                top: 50%;
                width: 100%;
                transform: rotate(-45deg);
            }
        }
    }

    .line-bar {
        display: block;
        width: 100%;
        background-color: $viridian;
        position: absolute;
        left: 0;
        height: toRem(3);
        border-radius: toRem(2);
        margin-top: toRem(-1.5);
    }

    .line-bar--1 {
        top: 0%;
        transform: rotate(0deg);
        width: 50%;
        transition: top 0.2s ease, transform 0.2s ease;

        .c-hamburger.is-active & {
            top: 50%;
            transform: rotate(45deg);
        }
    }

    .line-bar--2 {
        top: 50%;
        opacity: 1;
        transition: opacity 0.1s ease;

        .c-hamburger.is-active & {
            opacity: 0;
            transition: opacity 0.2s ease;
        }
    }

    .line-bar--3 {
        top: 100%;
        transform: rotate(0deg);
        width: 80%;
        transition: top 0.2s ease, transform 0.2s ease;

        .c-hamburger.is-active & {
            top: 50%;
            transform: rotate(-45deg);
        }
    }

    @include breakpoint-up(l) {
        display: none;
    }
}

.has-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;

    .link__parent {
        display: flex;
        align-items: center;
        gap: toRem(8);
    }

    .dropdown-menu {
        display: none;
        background-color: #fff;
        box-shadow: 0 toRem(4) toRem(8) rgba(0, 0, 0, 0.1);
        padding: $spacer--xl $spacer--2xl;
        z-index: 200;

        a {
            display: block;
            color: $viridian;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .gg {
        transition: transform 0.5s ease;
    }

    @include breakpoint-down(l) {
        .dropdown-menu {
            position: relative;
            display: none;
            box-shadow: none;
            padding: 0;
            width: 100%;
            margin-left: $spacer;
            padding-top: $spacer;

            a {
                margin-bottom: $spacer--s;
            }
        }

        .link__parent {
            width: 100%;
            justify-content: space-between;
        }

        &.is-open {
            .dropdown-menu {
                display: block;
            }

            .gg {
                transform: rotate(0deg);
            }
        }
    }

    @include breakpoint-up(l) {
        .dropdown-menu {
            position: absolute;
            top: toRem(60);
            border: 1px solid $gray-20;
            left: $spacer--xl;
            min-width: toRem(640);
        }

        &:hover {
            .dropdown-menu {
                display: flex;
                flex-direction: column;
                gap: $spacer--m;
            }

            .gg {
                transform: rotate(0);
            }
        }
    }
}

@mixin chevron {
    border: 2px solid transparent;
    border-radius: 100px;
}

@mixin chevron-after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    bottom: 2px;
}

.gg {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
}

.gg--chevron-up,
.gg--chevron-down,
.gg--chevron-left,
.gg--chevron-right {
    @include chevron();

    &::after {
        @include chevron-after();
    }
}

.gg--chevron-down {
    transform: rotate(180deg);
}

.gg--chevron-left {
    transform: rotate(-90deg);
}

.gg--chevron-right {
    transform: rotate(90deg);
}