dialog {
  display: block;
  border: 0;
}

.no-scroll {
  overflow: hidden;
  position: relative;
}

.simple-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;
  opacity: 0.6;
  z-index: 666;
  cursor: pointer;

  &[data-background-click="disabled"] {
    cursor: auto;
  }
}

.simple-modal {
    overflow: visible;
  > * {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.simple-modal-close {
  position: fixed;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 668;
  border-radius: 1em;
  background-color: transparent;
  line-height: 1;
  border: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 50% !important;
  width: toRem(38);
	height: toRem(38);
	border: toRem(3) solid $coyote;

  @include breakpoint-up(l) {
    top: $spacer;
    right: $spacer;
  }
}

.simple-modal__content {
  border-radius: $border-radius;
  position: fixed;
  top: 7%;
  width: 90%;
  left: 5%;
  max-height: 88vh;
  background: #fff;
  z-index: 667;
  padding: 2rem 1rem;
  right: auto;
  overflow: scroll;

  p {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  @include breakpoint-up(l) {
      padding: 4rem;
      width: 70%;
      left: 15%;
  }
}

.simple-modal-close {
  background-color: white;
}