.find-attorney {
    .c-hero-lp {
        background-color: rgba($gray-20, 0.3);
    }

    .benefits-of-filing,
    .get-started {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    .meso-lawyer-can-help {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
        background-color: #F7F7F7;

        .media-block__quote {
            .overline {
                text-align: center;
                margin-bottom: $spacer--l;
            }
        }
    }

    .benefits-of-filing {
        background-color: rgba($gray-30, 0.3);

        .action-block--card {
            background-color: white;

            img {
                max-width: toRem(64);
            }
        }
    }

    .get-started {
        text-align: center;
        border-bottom: solid toRem(1) $gray-20;

        .description {
            max-width: toRem(750);
            margin-inline: auto;
            margin-bottom: $spacer--4xl;
        }

        .action-block {
            h3 {
                color: #fff;
                margin-top: $spacer--l;
            }

            img {
                max-width: toRem(84);
            }

            p {
                font-size: toRem(16);
            }
        }
    }

    .overline {
        text-transform: uppercase;
        font-size: toRem(14);
        line-height: toRem(18);
        font-weight: 700;
        letter-spacing: toRem(1);
        margin-bottom: toRem(8);
        color: $turquoise;
    }

    .featured-in {
        background-color: #fff;
        padding: $spacer--xl $spacer--l;
        border-radius: $border-radius;
        margin-top: $spacer--2xl;

        .featured-imgs {
            display: flex;
            column-gap: $spacer--l;
            row-gap: $spacer;
            flex-wrap: wrap;
            align-items: center;

            img {
                height: fit-content;
            }
        }
    }

    @include breakpoint-up(l) {
        .subheading-p {
            margin-bottom: 0;
        }

        .hero-image {
            max-height: toRem(650);
            margin-bottom: 0;
        }

        .media-block--media-small {
            .media-block__image {
                max-height: toRem(450);
                height: 100%;
                object-fit: cover;
            }
        }

        .benefits-of-filing {
            text-align: center;
            padding-top: $spacer--5xl;
            padding-bottom: $spacer--5xl;

            h1 {
                margin-bottom: $spacer--xl;
            }

            .action-blocks {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .meso-lawyer-can-help {
            padding-top: $spacer--5xl;
            padding-bottom: $spacer--5xl;

            .media-block {
                align-items: center;
            }

            .media-block__quote {
                min-width: toRem(500);
            }
        }

        .cta-factbox {
            margin-top: $spacer--xl;
        }

        .action-block--card {
            text-align: center;
        }
    }

    @include breakpoint-down(l) {
        .hero-image {
            object-position: 0 50%;
        }

        .media-block--media-small {
            .media-block__image {
                display: block;
                max-height: toRem(336);
                width: 100%;
                object-fit: cover;
            }
        }

        .media-block__media {
            margin-bottom: $spacer--l;
        }

        .meso-lawyer-can-help {
            .media-block {
                gap: $spacer--2xl;
                flex-direction: column-reverse;
            }

            .media-block__quote {
                margin-inline: auto;
            }
        }

        .featured-in {
            text-align: center;

            .featured-imgs {
                justify-content: center;
            }
        }
    }

    @include breakpoint-between(s,l) {
        .media-block--media-small {
            .media-block__image {
                border-radius: toRem(8);
            }
        }
    }

    @include breakpoint-down(s) {
        .media-block--media-small {
            .media-block__image {
                @include make-full-width;
                max-height: toRem(228);
            }
        }

        .get-started {
            .action-block {
                h3 {
                    margin-bottom: $spacer--xs;
                }
            }
        }
    }
}