.qualify-for-compenstation {
    display: flex;
    flex-direction: column;
    gap: $spacer--l;
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;

    .qualify-main {
        p {
            margin-bottom: $spacer--l;
        }
    }

    .qualify-cards {
        display: flex;
        flex-direction: column;
        gap: $spacer--l;
    }

    .qualify-card {
        display: flex;
        flex-direction: column;
        padding: $spacer--l;
        border: solid toRem(1) $dark;
        border-radius: $border-radius;
        background-color: $light-cream;
    }

    .qualify-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacer--2xs;
        justify-content: space-between;
        margin-bottom: $spacer;

        h3 {
            margin-bottom: 0;
        }

        span {
            font-weight: 700;
            font-style: italic;
            color: $viridian;
            font-size: toRem(20);
            line-height: toRem(24);
        }
    }

    @include breakpoint-up(l) {
        flex-direction: row;
        gap: $spacer--4xl;
        padding-top: $spacer--6xl;
        padding-bottom: $spacer--6xl;

        .qualify-header {
            margin-bottom: $spacer--l;
        }

        .c-button-green--desktop {
            margin-top: $spacer--xl;
        }

        .c-button-green--mobile {
            display: none;
        }
    }

    @include breakpoint-down(l) {
        .c-button-green {
            &--mobile {
                margin-top: $spacer--l;
                justify-content: center;
                width: 100%;
            }

            &--desktop {
                display: none;
            }
        }
    }
}