.homepage {
    .who-is-eligible,
    .types-meso-claims,
    .advocating,
    .empowering {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    .action-blocks {
        gap: $spacer--l;
    }

    .empowering {
        .w-container-sm {
            max-width: calc(toRem(800) + toRem(128));
        }
    }

    .who-is-eligible {
        h2 {
            margin-bottom: $spacer--xl;
        }
    }

    .action-blocks {
        margin-top: $spacer--xl;
    }

    @include breakpoint-up(l) {
        .c-hero {
            .hero-image {
                height: toRem(430);
                object-fit: cover;
            }
        }

        .who-is-eligible,
        .types-meso-claims,
        .advocating,
        .empowering {
            padding-top: $spacer--5xl;
            padding-bottom: $spacer--5xl;
        }

        .who-is-eligible,
        .types-meso-claims {
            .section-header {
                text-align: center;
            }
        }

        .who-is-eligible {
            h2 {
                margin-bottom: $spacer--4xl;
            }
        }

        .empowering {
            h2 {
                text-align: center;
            }
        }

        .action-blocks {
            margin-top: $spacer--4xl;
        }
    }

    @include breakpoint-down(l) {
        .c-hero {
            .hero-image {
                object-position: unset;
            }
        }
    }
}