@import "base";

html {
	scroll-padding-top: 1.5rem;//better padding on scroll
}

/* Smooth scrolling IF user doesn't have a preference due to motion sensitivities */
@media screen and (prefers-reduced-motion: no-preference) {
	html {
		scroll-behavior: smooth;
	}
}

img {
	max-width: 100%;
	height: auto;
}

sup {
	font-size: 0.8rem;
	vertical-align: super;
	position: relative;
	top: -0.1rem;
	margin-left: 0.1rem;
	margin-right: 0.1rem;
}