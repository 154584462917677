.related-pages {
    max-width: toRem(332);

    .related-pages-title {
        font-size: toRem(20);
        font-weight: 700;
        margin-bottom: 1rem;
        color: $dark;
    }

    .related-list {
        padding-left: 0;
    }

    .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacer--s;
        font-size: toRem(14);
        line-height: toRem(21);
        font-weight: 400;
        padding-bottom: $spacer--s;
        border-bottom: solid toRem(1) $gray-20;

        a {
            margin-left: $spacer--xs;

            &:hover {
                color: $viridian;
            }
        }

        svg {
            transform: scale(0.5);
            overflow: visible;

            path {
                fill: $viridian;
            }
        }

        &:not(:first-of-type) {
            padding-top: $spacer--s;
        }
    }
}

.toc-mobile {
    display: none;

    &.is-visible {
        top: 0;
        transition: top 0.5s ease-out;
    }

    .related-list {
        height: 0;
        min-height: 0;
        overflow: hidden;
        margin: 0;
        list-style: none;
        transition: min-height 0.5s ease-out;
        background-color: #fff;
    }

    @include breakpoint-down(l) {
        width: 100%;
        top: toRem(-500);
        transition: top 0.5s ease-in;
        display: flex;
        position: fixed;
        display: block;
        box-shadow: 0 toRem(6) toRem(14) rgba($dark, 0.1);

        .toc-button {
            width: 100%;
            border-radius: 0;
            font-size: toRem(14);

            svg {
                transform: scale(0.7);
                overflow: visible;
            }

            &.is-active {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .related-list {
            &.is-active {
                height: 100%;
                min-height: toRem(200);
                width: 100%;
                padding: $spacer $spacer--l $spacer--xl;
                transition: min-height 0.5s ease-in;
            }
        }

        .list-item {
            padding: $spacer--xs 0;
            border-bottom: solid toRem(1) $gray-20;
        }
    }
}
